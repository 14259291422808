@import 'page-layout.css';
@import 'utilities.css';

#root {
  height: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

/* ヘッダーの色は固定*/

th {
  background-color: aliceblue !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

td {
  font-size: 14px !important;
}

pre {
  margin: 0px !important;
}

/* ヘッダーの色は固定*/

.table_background_color_aliceblue {
  background-color: aliceblue !important;
}

/* ローディングアイコン位置*/

.loading {
  position: fixed;
  top: 40%;
  left: 49%;
  z-index: 1400;
}

.cursor_pointer {
  cursor: pointer;
}

.field_size_2 {
  width: 2%;
} 

/* Field size */
.field_size_3 {
  width: 3%;
}

.field_size_4 {
  width: 4%;
}

.field_size_5 {
  width: 5%;
}

.field_size_6 {
  width: 6%;
}

.field_size_8 {
  width: 8%;
  max-width: 8%;
}

.field_size_10 {
  width: 10%;
  max-width: 10%;
}

.field_size_12 {
  width: 12%;
  max-width: 12%;
}

.field_size_15 {
  width: 15%;
  max-width: 15%;
}

.field_size_18 {
  width: 18%;
  max-width: 18%;
}

.field_size_20 {
  width: 20%;
  max-width: 20%;
}

.field_size_22 {
  width: 22%;
  max-width: 22%;
}

.field_size_30 {
  width: 30%;
  max-width: 30%;
}

.field_size_35 {
  width: 35%;
  max-width: 35%;
}

.field_size_40 {
  width: 40%;
  max-width: 40%;
}

.field_size_45 {
  width: 45%;
  max-width: 45%;
}

.field_size_50 {
  width: 50%;
  max-width: 50%;
}

.field_size_60 {
  width: 60%;
  max-width: 60%;
}

.field_size_70 {
  width: 70%;
  max-width: 70%;
}

.field_size_75 {
  width: 75%;
  max-width: 75%;
}

.field_size_80 {
  width: 80%;
  max-width: 80%;
}

.field_size_90 {
  width: 90%;
  max-width: 90%;
}

.field_min_size_15 {
  min-width: 15px !important;
}

.field_min_size_30 {
  min-width: 30px !important;
}

.field_min_size_50 {
  min-width: 50px !important;
}

.field_min_size_70 {
  min-width: 70px !important;
}

.field_min_size_100 {
  min-width: 100px !important;
}

.field_min_size_125 {
  min-width: 125px !important;
}

.field_min_size_150 {
  min-width: 150px !important;
}

.field_min_size_200 {
  min-width: 200px !important;
}

.field_min_size_225 {
  min-width: 225px !important;
}

.field_min_size_250 {
  min-width: 250px !important;
}

.field_min_size_300 {
  min-width: 300px !important;
}

.field_min_size_350 {
  min-width: 350px !important;
}

.field_min_size_400 {
  min-width: 400px !important;
}

.field_min_size_500 {
  min-width: 500px !important;
}

.field_min_size_600 {
  min-width: 600px !important;
}

.field_min_size_700 {
  min-width: 700px !important;
}

.field_min_size_800 {
  min-width: 800px !important;
}

.field_min_size_900 {
  min-width: 900px !important;
}

.field_min_size_1000 {
  min-width: 1000px !important;
}

.field_min_size_1200 {
  min-width: 1200px !important;
}

.field_max_size_200 {
  max-width: 200px !important;
}

.field_max_size_300 {
  max-width: 300px !important;
}

/* Button */
.button_color {
  background-color: #333333 !important;
}

.button_color_green {
  background-color: #66cc00 !important;
}

.button_color_red {
  background-color: #dc3545 !important;
}

.button_color_yellow {
  background-color: gold !important;
}

.button_color_info {
  background-color: #17a2b8 !important;
}

.button_color_blue {
  background-color: #2196f3 !important;
}

.button_margin {
  margin: 3px !important;
}

.button_size {
  width: 124px !important;
}

.checkbox_radio {
  padding: 4px !important;
  color: #333333 !important;
}

.disabled_checkbox_radio {
  padding: 4px !important;
  color: #aaa5a5 !important;
}

.header_color {
  background-color: white;
  color: black;
}

.bg_black {
  background: black;
  color: white;
}

.text_align_right {
  display: inline-block;
  text-align: right;
}

.text_align_center {
  display: inline-block;
  text-align: center;
}

.text_align_left {
  display: inline-block;
  text-align: left;
}

.scroll_area_800 {
  max-height: 800px;
  overflow: auto;
}

.scroll_area_700 {
  max-height: 700px;
  overflow: auto;
}

.scroll_area_683 {
  max-height: 683px;
  overflow: auto;
}

.scroll_area_650 {
  max-height: 650px;
  overflow: auto;
}

.scroll_area_600 {
  max-height: 600px;
  overflow: auto;
}

.scroll_area_500 {
  max-height: 500px;
  overflow: auto;
}

.scroll_area_400 {
  max-height: 400px;
  overflow: auto;
}

.scroll_area_350 {
  max-height: 350px;
  overflow: auto;
}

.scroll_area_300 {
  max-height: 300px;
  overflow: auto;
}

.scroll_area_280 {
  max-height: 250px;
  overflow: auto;
}

.scroll_area_min_650 {
  min-height: 650px;
}

.scroll_area_min_600 {
  min-height: 600px;
}

.scroll_area_min_500 {
  min-height: 500px;
}

.scroll_area_min_470 {
  min-height: 470px;
}

.scroll_area_min_350 {
  min-height: 350px;
}

.scroll_area_min_300 {
  min-height: 300px;
}

.scroll_area_min_280 {
  min-height: 250px;
}

.card_size {
  max-width: 90%;
  max-height: 300px;
}

.data_card_size {
  min-width: 250px;
  max-width: 350px;
  max-height: 300px;
  margin: 10px 0;
}

.home_card_size {
  min-width: 100%;
  min-height: 100%;
  background-color: aliceblue !important;
}

.card_media_size {
  height: 180px;
  width: 350px;
  border: 1px solid #f1ecec;
}

.modal_size_small {
  min-width: 400px;
  width: 30%;
  max-width: 30%;
  max-height: 85%;
  overflow: auto;
  margin-top: 15px;
}

.modal_size_mid {
  min-width: 600px;
  width: 54%;
  max-width: 50%;
  max-height: 85%;
  overflow: auto;
  margin-top: 15px;
}

.modal_size_mid_setting {
  min-width: 800px;
  width: 65%;
  max-width: 70%;
  max-height: 85%;
  overflow: auto;
  margin-top: 15px;
}

.modal_size_big {
  min-width: 700px;
  width: 70%;
  max-width: 70%;
  max-height: 85%;
  overflow: auto;
  margin-top: 15px;
}

.product_entry_table {
  width: 100%;
  min-width: 1100px;
  overflow: auto;
}

.search_table {
  width: 100%;
}

.main_card_min_size {
  min-width: 1200px !important;
  overflow: auto;
}

.header_min_size {
  min-width: 1200px !important;
}

.product_list_card_size {
  min-width: 100%;
  min-height: 150px;
  max-height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product_list_card_minsize {
  min-width: 1200px;
}

.product_checkbox_card_minsize {
  min-width: 130px;
}

.border_bottom {
  border-bottom: 1px solid black;
}

.page_header {
  border-bottom: 2px solid gray;
}

.row_form_item {
  border-bottom: 0.5px solid #f1ecec;
  margin: 0 !important;
  width: 100% !important;
}

.product_entry_table_header_color {
  background-color: palevioletred !important;
}

.product_entry_table_sub_title_color {
  background-color: lightyellow !important;
}

.product_entry_table_sub_column_color {
  background-color: #e6e6e6 !important;
}

.product_group_table_header_green {
  background-color: #4a7e17 !important;
}

.product_group_table_header_info {
  background-color: #80b3b9 !important;
}

.product_group_table_header_purple {
  background-color: rgb(219, 53, 219) !important;
}

.master_google_map {
  border: 1px solid #000;
  height: 300px;
  max-width: 500px;
  min-width: 200px;
}

.forward_icon {
  position: relative;
  font-size: 4rem !important;
  top: 30px;
}

.compare_arrow_icon {
  position: relative;
  font-size: 3.5rem !important;
  top: -10px;
  margin: 0 auto;
}

.product_group_add_button {
  position: relative;
  background-color: #4a7e17 !important;
  font-size: 12px !important;
  top: 280px;
}

.product_group_delete_button {
  position: relative;
  top: 50px;
}
.product_radio {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.help_text {
  line-height: 45px;
  color: #989898;
}

/* サジェストの設定(以下がないとおかしくなってた)*/

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0 !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
  padding-right: 14px !important;
  padding-left: 14px !important;
  margin-top: -2px !important;
}

.MuiAutocomplete-popupIndicator {
  display: none !important;
}

.MuiInputBase-root {
  height: 35px !important;
}

.MuiInputBase-multiline {
  height: auto !important;
}

.MuiFormControl-marginDense {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.MuiGrid-spacing-xs-1 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.MuiListItemIcon-root {
  min-width: 30px !important;
}

.MuiTableCell-body {
  font-size: 16px !important;
}

.MuiDialog-paperScrollPaper {
  display: flex !important;
  max-height: none !important;
  flex-direction: column !important;
}

.MuiDialog-paper {
  overflow-y: hidden !important;
}

.MuiTablePagination-selectIcon {
  top: calc(50% - 12px) !important;
}

.MuiTableRow-root.Mui-selected {
  background-color: rgba(212, 211, 211, 0.603) !important;
}

table.layoutfix {
  table-layout: fixed;
}

table.layoutfix td {
  overflow-wrap: break-word;
}

.Select-menu-outer {
  display: block;
  z-index: 100;
}

.margin_right_L {
  margin-right: 1rem !important;
}
.title {
  padding: 6px 24px 6px 16px;
}
.tab_img {
  width: 40px;
  height: 40px;
}
.break_bottom_line {
  border-bottom: 1px solid #ccc;
}
.text_grey {
  color: #ccc;
}
.text_black {
  color: #000;
}
.text_left {
  text-align: left !important;
}
.text_center {
  text-align: center !important;
}
.text_right {
  text-align: right !important;
}

.margin_auto {
  margin: auto;
}
.border_black_2p {
  border: 2px solid black;
  border-radius: 7px;
}
.border_cyan_2p {
  border: 2px solid #18c090;
  border-radius: 7px;
}
.item_taxi_circle .circle {
  height: 62px;
  background: grey;
  color: white;
  width: 62px;
  text-align: center;
  flex-direction: row;
  align-items: center;
  border-radius: 62px;
  float: left;
}
.circle img {
  width: 50px;
  height: 50px;
}
.item_black .left {
  background: black;
  height: 50px;
  color: white;
  width: 30px;
  float: left;
  text-align: center;
}
.item_black .right {
  color: black;
  text-align: center;
}
.padding_0 {
  padding: 0px !important;
}
.padding_left_20 {
  padding-left: 20px !important;
}

.border_orange_2p {
  border: 2px solid #fe483b;
  border-radius: 7px;
}

.border_grey_2p {
  border: 2px solid grey;
  border-radius: 7px;
}
.border_green_2p {
  border: 2px solid green;
  border-radius: 7px;
}
.item_orange {
  margin-bottom: 25px !important;
}
.item_orange .left {
  height: 50px;
  background: #fe483b;
  color: white;
  width: 30px;
  float: left;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item_orange .right {
  color: #fe483b;
  text-align: center;
}
.item_green .left {
  height: 50px;
  background: green;
  color: white;
  width: 30px;
  float: left;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item_green .right {
  color: green;
  text-align: center;
}
.item_cyan .left {
  height: 50px;
  background: #18c090;
  color: white;
  width: 30px;
  float: left;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item_cyan .right {
  color: #18c090;
  text-align: center;
}
.item_taxi_circle {
  width: 60px;
  height: 60px;
}
.item_grey .left {
  height: 50px;
  background: grey;
  color: white;
  width: 30px;
  float: left;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item_grey .right {
  color: grey;
  text-align: center;
}
.item_booking {
  border-radius: 10px;
  padding: 10px;
  width: 70px;
  height: 70px;
}
.item_booking img {
  width: 100%;
  height: 100%;
}
.item_booking_bg_cyan {
  background: #18c090;
}
.color_cyan {
  color: #18c090;
}
.item_booking_bg_grey {
  background: grey;
}
.item_booking_bg_blue {
  background: blue;
}
.item_booking_bg_orange {
  background: #fe483b;
}
.font_color_orange {
  color: #fe483b !important;
}
.item_booking_bg_black {
  color: white;
  background: black;
  width: 67px;
  height: 67px;
  text-align: center;
}
.background_black {
  background: #000 !important;
  color: #fff !important;
}

.background_pink {
  background: #ffc4d7 !important;
  color: #fff !important;
}

.btn_color_green {
  background: #18c090 !important;
  color: white;
}
.item_center_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg_grey {
  background: #ccc;
}
.mt_10 {
  margin-top: 10px;
}
.left {
  float: left !important;
}
.left img {
  width: 100%;
  height: 90%;
}
.right {
  float: right !important;
  width: 70%;
}
.height_80_p {
  height: 50px;
  width: 120px;
}
.height_80_p h3 {
  line-height: 1;
}
.border_complete {
  border: 5px dashed blue;
  padding: 15px;
  width: 100%;
  color: blue;
}
.margin_20 {
  margin: 20px !important;
}

.error {
  color: red;
}
.map {
  height: 300px;
}

.success {
  color: #18c090;
}
.field_times {
  padding: 0.88rem 0rem;
}
.day_of_week {
  padding: 1rem 0.8rem;
  box-shadow: none !important;
}
.day_of_week_container {
  margin-left: 6.4rem;
  text-align: center;
}
.box_shadow {
  box-shadow: none !important;
}

.hightlight_img {
  height: 90%;
}
.content_media_img {
  height: 140px;
  width: 100%;
}

.relative {
  position: relative;
}

.middle_line_right {
  border: 2px solid;
  position: absolute;
  right: -25px;
  top: 25px;
  width: 20px;
}

.middle_line_left {
  border: 2px solid;
  position: absolute;
  left: -25px;
  top: 25px;
  width: 20px;
}

.space_M {
  margin: 10px;
}

.view_cart {
  position: fixed !important;
  right: 50px;
  bottom: 50px;
}
.quantity {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  padding: 15px;
  background: grey;
  color: black;
}
.height_250p {
  height: 250px;
}

.width_10 {
  width: 10% !important;
}

.chart-wrapper {
  /* max-width: 1150px; */
  /* padding: 0 10px; */
  margin: 0 auto;
}
.chart-values,
.chart-bars {
  margin-left: -40px !important;
  position: relative;
}

.chart-bars li {
  margin-left: 0px !important;
}

/* CHART-VALUES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.chart-wrapper .chart-values {
  position: relative;
  display: flex;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}

.chart-wrapper .chart-values li {
  flex: 1;
  min-width: 100px;
  text-align: center;
  border-right: dotted 2px #ccc;
  position: relative;
}

.chart-wrapper .chart-values li:first-child {
  border-left: dotted 2px #ccc;
}

/* CHART-BARS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.chart-wrapper .chart-bars li {
  /* position: relative; */
  margin-bottom: 0px !important;
  padding: 15px 0px;
  transition: all 0.65s linear 0.2s;
  border-bottom: dotted 1px;
  height: 50px;
}
.chart-wrapper .chart-bars li:first-child {
  border-top: dotted 1px;
}

.chart-wrapper .chart-bars li .border_right {
  border-right: dotted 1px;
}

.chart-wrapper .chart-bars li span {
  position: absolute;
  /* margin-bottom: 15px; */
  font-size: 10px;
  padding: 5px 0px;
  opacity: 1;
  transition: all 0.65s linear 0.2s;
  cursor: pointer;
}

.time_data:hover {
  background-color: #17a2b8;
}

.chart-values li {
  margin-left: 0px !important;
  font-size: 10px !important;
}

.time_header {
  position: absolute;
  left: -14px;
}

.time_header_right {
  position: absolute;
  right: 0px;
}

@media screen and (max-width: 600px) {
  .chart-wrapper .chart-bars li {
    padding: 10px;
  }
}

.space_left_S {
  margin-left: 5px !important;
}

.invalid div input {
  color: red !important;
}

.fixed_cell:first-child {
  text-align: left !important;
}

.vehicle_drivers {
  overflow-x: auto;
}
.disabled_color {
  color: rgba(118, 118, 118, 0.3) !important;
  cursor: not-allowed;
}

/* GRID_TABLE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.table_border {
  border: 0, 5px solid #e8e8e8;
}

.max-length-label {
  margin-left: 10px;
}

.box-group-input {
  display: flex;
  align-items: center;
}

.disable-option-custom {
  color: #a3a3a3 !important;
}

.ml-20-custom {
  margin-left: 20px !important;
}

.list-error {
  display: flex;
  flex-direction: column;
}

.list-error .MuiFormHelperText-root {
  display: unset;
}

.unset-list {
  padding-left: unset;
  margin-left: unset;
}

.opacity-disable{
  opacity: 0.5;
}

.table_color_header {
  background-color: #8cd8e4;
}

.table_title_header {
  padding: 10px 0px 10px 20px;
  font-weight: bold;
}
