/* Display */

.display_flex {
  display: flex !important;
}

.display_grid {
  display: grid !important;
}

.display_inline_grid {
  display: inline-grid;
}

.display_contents {
  display: contents !important;
}

.display_block {
  display: block;
}

.cell_fixed_right {
  position: sticky;
  right: 0px;
  background: rgb(255, 255, 255);
  box-shadow: -3px 0px 1px -1px rgb(0 0 0 / 20%);
}

.cell_fixed_left {
  position: sticky !important;
  left: 0px;
  background-color: #fff;
  box-shadow: 3px 0px 1px -1px rgb(0 0 0 / 20%);
}

/* Font */

.font_size_mini {
  font-size: 8px;
}

.font_size_small {
  font-size: 12px;
}

.font_size_small_regular {
  font-size: 16px;
}

.font_size_mid {
  font-size: 18px !important;
}

.font_size_big {
  font-size: 20px !important;
}

.font_bold {
  font-weight: bold !important;
}

.font_color_blue {
  color: blue !important;
}

.font_color_mediumblue {
  color: mediumblue !important;
}

.font_color_red {
  color: red !important;
}

.font_color_white {
  color: white !important;
}

.font_color_black {
  color: black !important;
}

.font_color_grey {
  color: gray !important;
}

.font_color_pink {
  color: #db7093 !important;
}

/* Dimensions */

.width_25p {
  width: 25px;
}

.width_50p {
  width: 50px;
}

.width_75p {
  width: 75px;
}

.width_100p {
  width: 100px !important;
}

.width_125p {
  width: 125px !important;
}

.width_150p {
  width: 150px !important;
}

.width_175p {
  width: 175px !important;
}

.width_200p {
  width: 200px !important;
}

.width_225p {
  width: 225px;
}

.width_250p {
  width: 250px;
}

.width_300p {
  width: 300px;
}

.width_400p {
  width: 400px !important;
}

.width_700p {
  width: 700px;
}

.width_800p {
  width: 800px;
}

.width_5 {
  width: 5% !important;
}

.width_7 {
  width: 7.5% !important;
}

.width_10 {
  width: 10% !important;
}

.width_15 {
  width: 15% !important;
}

.width_20 {
  width: 20% !important;
}

.width_30 {
  width: 30% !important;
}

.width_50 {
  width: 50% !important;
}

.width_80 {
  width: 80% !important;
}

.width_85 {
  width: 85% !important;
}

.width_60 {
  width: 60% !important;
}

.width_90 {
  width: 90% !important;
}

.width_100 {
  width: 100% !important;
}

.flex_1 {
  flex: 1;
}

.height_100per {
  height: 100% !important;
}

.max_input_adornment {
  width: 170px;
}

/* Color */

.color-success {
  color: #18c090 !important;
}

.color-info {
  color: #30c0f0 !important;
}

.color-danger {
  color: #ff483c !important;
}

.color-light {
  color: #ffffff !important;
}

.color-dark {
  color: #000000 !important;
}

.color-secondary {
  color: #6c757d !important;
}

.color-disabled {
  color: #c8c8c8 !important;
}

/* Background color */

.background-color-success {
  background-color: #18c090 !important;
}

.background-color-info {
  background-color: #30c0f0 !important;
}

.background-color-danger {
  background-color: #ff483c !important;
}

.background-color-light {
  background-color: #ffffff !important;
}

.background-color-dark {
  background-color: #000000 !important;
}

.background-color-secondary {
  background-color: #6c757d !important;
}

.background-color-disabled {
  background-color: #c8c8c8 !important;
}

.background-color-down {
  background-color: #51ec9f !important;
}

.background-color-up {
  background-color: #eb7c51 !important;
}

/* Font size */

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-23 {
  font-size: 23px;
}

.font-25 {
  font-size: 25px;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-600 {
  font-weight: 600;
}

/* Margin */

.margin-item-0 {
  margin: 0px 24px !important;
}

.margin-item-12 {
  margin: 12px 24px !important;
}

.margin-right-24 {
  margin-right: 24px !important;
}

.margin-left-24 {
  margin-left: 24px !important;
}

.margin-0 {
  margin: 0px !important;
}

/* Padding */

.padding-item-0 {
  padding: 0px !important;
}

.padding-item-6 {
  padding: 6px 24px !important;
}

.padding-item-12 {
  padding: 12px 24px !important;
}

.padding-item-15 {
  padding: 15px !important;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.padding-left-24 {
  padding-left: 24px !important;
}

.padding-right-24 {
  padding-right: 24px !important;
}

.padding-top-12 {
  padding-top: 12px !important;
}

.padding-top-14 {
  padding-top: 14px !important;
}

.padding-y-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

/* Align */

.base-line {
  align-items: baseline;
}

.border_bottom_none {
  border-bottom: none !important;
}

.white_space_nowrap {
  white-space: nowrap;
}

.line_hight_header {
  line-height: 3 !important;
}

.none_padding_top_bottom {
  padding: 0px 0px !important;
}