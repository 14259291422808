.logo_header {
  width: 150px;
}

.navlink_item {
  text-decoration: none;
}

.main_layout {
  margin: 12px 24px;
}

.grid_title_padding {
  padding-left: 20px !important;
}

.search_condition_title {
  padding: 20px 20px !important;
  font-weight: bold !important;
}

.item_border_left {
  border-left: 0.5px solid #f1ecec;
}

.box_image {
  display: block;
  box-sizing: border-box;
  border: 0.5px solid #f1ecec;
}

.cropped_image {
  object-fit: contain;
}

.select_modal_item {
  border: 0.5px solid #010101 !important;
  background-color: #e7dfdf;
}

.table_border {
  border: 0.5px solid #f1ecec;
}

.ant-table-cell-fix-left{
  position: sticky !important;
  left: 0px;
  z-index: 3 !important;
  background-color: #fff;
}

::placeholder {
  font-size: 12px;
}

.MuiFormHelperText-contained {
  margin-left: 0px!important;
}

.Mui-disabled[disabled] {
  cursor: not-allowed !important;
}

.background_color_red {
  background-color: #fbc9c9;
}
