.geofence_form {
  padding: 20px;
}

.progress_box {
  background: rgba(243, 248, 255, 1);
}

.service_group_box {
  display: flex;
  align-items: center;
}

.radius_box {
  padding: 20px;
  border-radius: 25px;
}

.error_box {
  background: rgba(255, 0, 0, 0.06);
  padding: 20px;
}

.success_box {
  background: rgba(52, 199, 89, 0.13);
  color: rgba(52, 199, 89, 1);
  padding: 20px;
  text-align: center;
}

.success_color {
  background-color: rgba(52, 199, 89, 1) !important;
  color: white !important;
}

.text_success {
  font-weight: 500;
}

.text_pending {
  font-weight: 500;
  color: rgba(0, 122, 255, 1);
}